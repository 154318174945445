<template>

  <div class="about">
    <div class="dimmer" v-if="loading"></div>
    <div class="scr">
      <div class="container is-max-desktop">
        <br>
        <b-steps type="is-primary" v-model="step" :has-navigation="false">
          <b-step-item :label="order[lang_number]">
            <br>
            <div class="columns ">
              <div class="column">
                <div class="box">
                  <div class="columns is-centered">
                    <div class="column is-narrow" v-for="selection in people" :key="selection.icon">
                      <div class="clickbtn" @click="select(selection.i)"
                        :class="{ selected: (selection.i == selected.people) }">
                        <img :src="selection.icon" class="clickbtn_num" />
                        <p class="p1">{{ selection.name[lang_number] }}</p>
                        <p class="p2" v-if="selection.price > 0">-{{selection.price}}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="tag is-primary">
                        <h3 class="title is-5 has-text-white has-text-weight-normal">{{night_price[lang_number]}}</h3>
                      </div>
                    </div>
                    <div class="column ">
                      <p><b>{{twonights[lang_number]}}:</b> {{price_builder.base_price[today]}}
                        Kč/{{night[lang_number]}}</p>
                      <p><b>{{threetosix[lang_number]}}:</b> {{tts}} Kč/{{night[lang_number]}}
                        <small>(-{{price_builder.threetosixsale[today]}}%)</small>
                      </p>
                      <p><b>{{sevenandmore[lang_number]}}:</b> {{sam}} Kč/{{night[lang_number]}}
                        <small>(-{{price_builder.sevenandmoresale[today]}}%)</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field :label="arrival[lang_number]">
                      <b-datepicker inline :locale="locale" :events="gc_events" v-model="selected.arrival"
                        @input="arrival_change" :month-names="nyaan" :selectable-dates="selectableDatesAr">
                      </b-datepicker>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field :label="depart[lang_number]">
                      <b-datepicker inline :locale="locale" :events="gc_events" v-model="selected.depart"
                        @input="depart_change" :month-names="nyaan" :selectable-dates="selectableDatesDe">
                      </b-datepicker>
                    </b-field>
                  </div>
                </div>
                <br>
              </div>

              <div class="column is-narrow">
                <div class="divide"></div>
              </div>
              <div class="column is-one-quarter">
                <div class="box">
                  <p class="is-size-5">{{order[lang_number]}}</p>
                  <h3 class="is-size-2 has-text-weight-bold is-marginless">{{total}} Kč</h3>
                  <small>cca €{{total / 25}}</small>
                  <br>
                  <br>
                  <b-button type="is-light is-primary has-text-weight-bold" @click="step = 1" icon-right="arrow-right">
                    {{continue_t[lang_number]}}</b-button>
                </div>
              </div>
            </div>
          </b-step-item>
          <b-step-item :label="info[lang_number]">
            <br>
            <div class="box">
              <div class="columns is-multiline">
                <div class="column is-half">
                  <b-field :label="name[lang_number]">
                    <b-input :placeholder="name_e[lang_number]" v-model="selected.person.name_surname" type="text">
                    </b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field :label="email[lang_number]">
                    <b-input :placeholder="email_e[lang_number]" v-model="selected.person.email" type="email"></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field :label="translations[lang_number].phoneNumber">
                    <VuePhoneNumberInput v-model="selected.person.phone" :show-code-on-list="true"
                      :translations="translations[lang_number]" @update="updatingPhone" color="#505243"
                      :default-country-code="codes[lang_number]"
                      :preferred-countries="['CZ', 'SK', 'DE', 'PL', 'AT']" />
                  </b-field>
                </div>
                <div class="column is-full">
                  <b-field :label="translations[lang_number].phoneNumber">
                    <b-field :label="additional[lang_number]">
                      <b-input maxlength="200" type="textarea" v-model="selected.person.additional_info"></b-input>
                    </b-field>
                  </b-field>
                </div>
                <div class="column">

                </div>
                <div class="column is-narrow">
                  <b-button type="is-light is-primary has-text-weight-bold" :disabled="is_disabled" @click="step = 2"
                    icon-right="arrow-right">
                    {{continue_t[lang_number]}}</b-button>
                </div>
              </div>
            </div>
          </b-step-item>
          <b-step-item :label="confirm[lang_number]">
            <br>
            <div class="box">
              <br>
              <h1 class="title has-text-centered has-text-primary">{{your_order[lang_number]}}</h1>
              <div class="price-tag">
                <h3 class="is-size-2  has-text-centered is-marginless">{{total}} Kč</h3>
              </div>
              <p class=" is-marginless  has-text-centered"><small>cca €{{total / 25}}</small></p>
              <p class=" is-marginless has-text-centered">
                <br>
                <b-button type=" is-warning has-text-weight-bold" size="is-large" :disabled="is_disabled"
                  @click="send_form">
                  {{done_t[lang_number]}}</b-button>
                <br>
                <br>
                <b-loading :is-full-page="true" v-model="loading" :can-cancel="true">
                  <img class="sending" src="../assets/sending.svg" />
                </b-loading>
                <img class="powered" src="../assets/powered.svg" />
              </p>

            </div>

          </b-step-item>
        </b-steps>

      </div>
    </div>

  </div>
</template>
<style>
.dimmer {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
}
.notices {
  z-index: 90000 !important;
}
.dropdown, .dropdown-menu {
  z-index: 90000!important;
}
:root {
  --price-tag-background: rgba(0, 0, 0, 0.3);
  --price-tag-height: 4.25rem;
}
</style>
<style scoped>
.powered {
  height: 16px;
  text-align: center;
}
.sending {
  height: 160px;
  text-align: center;
}


.price-tag {
  background: var(--price-tag-background);
  color: #fff;

  /* Center the price */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Used to position the triangle */
  position: relative;
width: 400px;
left: calc(50% - 200px);
  /* Size */
  height: var(--price-tag-height);

  /* Spacing */
  padding: 2rem 0.5rem;
}

/* The triangle */
.price-tag::before {
  content: '';

  border-color: transparent var(--price-tag-background) transparent transparent;
  border-style: solid;
  border-width: calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) 0rem;

  /* Position */
  left: 0px;
  position: absolute;
  top: 0px;
  transform: translate(-100%, 0px);
}

/* The dot */
.price-tag::after {
  content: '';

  /* Make it like a cirle */
  background: #fff;
  border-radius: 9999rem;

  /* Position */
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(1.5rem, -50%);

  /* Size */
  height: 1.5rem;
  width: 1.5rem;
}
.about {
  position: fixed;
  background: url("../assets/whiteback.png");
  background-size: cover;
  background-position: top center;
  z-index: 5;
  margin-bottom: 15px;
  width: 100%;
  height: calc(100% - 60px);

  padding-top: 113px;
}
.clickbtn_num {
  height: 3.5rem;
}
.clickbtn {
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  padding-left: 20px;
  border: 1px solid transparent;
  padding-right: 20px;
}
.divide {
  height: 100%;
  width: 1px;
  background-color:lightgrey
}
.clickbtn img {
  width: 95px;
}

.clickbtn .p1
{
  font-weight: bold;
}
.scr{
  overflow:scroll!important;
  height: 100%;
  width: 100%;
}
.clickbtn .p2
{
  color: green;
}

.clickbtn:hover
{
  background: lightgrey;
}

.clickbtn.selected {
  border: 1px solid lightgrey;
}
</style>
<script>
import ICalParser from 'ical-js-parser';
import axios from 'axios';
import {
  Base64
} from 'js-base64';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
var date = new Date();
date.setDate(date.getDate() + 1);
var dated = new Date();
dated.setDate(dated.getDate() + 8);
  export default {
    mounted() {
      axios.post('https://domanapeci.cz/scrape.php', {}, {  })
        .then(response => (this.setcal(response.data)));
      this.load_prices();
    },
  created() {
    this.selected.arrival = date;
    this.selected.depart = dated;
   this.$parent.dark = false;
   if (this.$route.params.dates.start != null)
   {
    this.selected.arrival = this.$route.params.dates.start;
    this.selected.depart = this.$route.params.dates.end;
     
     var self = this;
     setTimeout(function () { self.depart_change(); }, 500);
   }
    this.depart_change();
  },
  methods: {
    load_prices: function () {
console.log(this.today);
        var data = new FormData();
      data.append('bin', '/apps/persephone/cgi/accprices');
      data.append('stdin', 'get_prices(1)');
      const hostname = "https://domanapeci.cz/fujin3/api/rest/rest_call.php";
        axios.post(hostname, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            console.log(response);
            if (response.data.data.code == 0) {
              this.$buefy.snackbar.open({
                duration: 5000,
                message: response.data.data,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,

              })
            } else {
              setTimeout(() => (document.getElementsByClassName("scr")[0].scrollTo(0, 0)), 500);
              this.price_builder = response.data.data;
             
            }

          }).catch(function (error) {
            console.log(error);

          });
      
    },
    send_form: function () {
      if (this.selected.person.name_surname != "" && this.selected.person.email != "") {
        this.selected.total = this.total + " Kč";
        var data = new FormData();
        this.loading = true;
        data.append('bin', '/apps/hermes/cgi/message');
        data.append('stdin', 'send(' + Base64.encode(JSON.stringify(this.selected)) + ')');
        const hostname = "https://domanapeci.cz/fujin3/api/rest/rest_call.php";
        axios.post(hostname, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            console.log(response);
            if (response.data.data.code == 0) {
              this.$buefy.snackbar.open({
                duration: 5000,
                message: response.data.data.message,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,

              })
            } else {
              this.$buefy.snackbar.open({
                duration: 5000,
                message: response.data.data.message,
                type: 'is-info',
                position: 'is-top',
                actionText: 'Undo',
                queue: false,

              });
        this.loading = false;

              setTimeout(() => this.$router.push({ path: '/' }), 1500);
            }

          }).catch(function (error) {
            console.log(error);

          });
      }
      else
      {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: "Objednávka nelze odeslat. Prosím napište nám na podpora.veshop@vespotok.net",
          type: 'is-danger',
          position: 'is-top',
          actionText: 'Undo',
          queue: false,

        });
      }
      console.log(this.selected);
    },
    setcal: function(data) {
      const resultJSON = ICalParser.toJSON(data);
      var arr = [];
      resultJSON.events.forEach(function (gc_event) {
        var sstring = gc_event.dtstart.value;
        var syear = sstring.substring(0,4);
        var smonth = sstring.substring(4,6)-1;
        var sday = sstring.substring(6, 8);
        var start = new Date(syear, smonth, sday);
        var estring = gc_event.dtend.value;
        /*var eyear = estring.substring(0, 4);
        var emonth = estring.substring(4, 6) - 1;*/
        var eday = estring.substring(6, 8);
        if((eday - sday) > 0)
        {
          var length = (eday - sday);
          for(var i = 0; i <= length; i++)
          {
            
            arr.push(new Date(syear, smonth, (parseInt(sday) + i)));
          }
          
        } 
        else
        { 
          arr.push(start);
        }
        
      });
      this.gc_events = arr;
    },
    updatingPhone: function(data)
    {
      this.selected.person.phone_format = data.formatInternational;
      this.selected.person.phone_correct = data.isValid;
    },
    parseDate: function(string) {
      var year = string.substring(0,4);
      var month = string.substring(5,6);
      var day = string.substring(7,8);
      return new Date(year, month, day);
    },

    dateCount: function(date1, date2) {
      var diff = date1 - date2;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    },
    addDays: function(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    select: function(i) {
      this.selected.people = i;
    },
    arrival_change: function () {
      for(var i = 7; i > 0; i--)
      {
        if (this.selectableDatesDe(this.addDays(this.selected.arrival, i)))
        {
          this.selected.depart = this.addDays(this.selected.arrival, i);
          if (this.check_ifbookedinbetween()) {
            this.selected.depart = this.selected.arrival;
          }

          break;
        }
      }
     
     
    },
    depart_change: function() {
      if (this.dateCount(this.selected.depart,this.selected.arrival) < 2) {
        this.selected.depart = this.addDays(this.selected.arrival, 2);
        this.$buefy.snackbar.open({
          message: this.select_twodays[this.lang_number],
          type: 'is-warning',
          position: 'is-top',

        });
      }
      if (this.check_ifbookedinbetween()) {
        this.selected.depart = this.addDays(this.selected.arrival, 0);
        this.$buefy.snackbar.open({
          message: this.select_other[this.lang_number],
          type: 'is-warning',
          position: 'is-top',
          
          });
      }
      
    },
    check_ifbookedinbetween: function() {
      var booked = false;
      for(var i = new Date(this.selected.arrival); i < this.selected.depart; i.setDate(i.getDate()+1))
      {
          if(!this.selectableDatesDe(i))
          {
            booked = true;
          }
      }
      return booked;
    },
    selectableDatesAr: function(date) {
      if(date >= new Date())
      {
        const dtt = this.gc_events.find(
          dtt => dtt.getTime() === date.getTime(),
        );
        if (dtt != undefined)
        {
          return false
        }
        else
        {
          return true
        }
      }
      else
      {
        return false;
      }
    }, selectableDatesDe: function (date) {
      if (date >= new Date()) {
        if(date >= this.selected.arrival)
        { 
          if (date <= this.addDays(this.selected.arrival, 365))
            {
            const dtt = this.gc_events.find(
              dtt => dtt.getTime() === date.getTime(),
            );
            if (dtt != undefined) {
              return false
            }
            else {
              return true
            }
            }
            else
            { return false; }
        }
        else { return false; }
      }
      else {
        return false;
      }
    },
  }, props: {
    dates: {
      type: Object,
      required: false
    }
  },
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      loading: false,
      gc_events: [],
      arrival: [
        "Příjezd",
        "Arrival",
        "도착",
        "Ankunft",
        "Kxētinnae",
        "Przyjazd"
      ],
      depart: [
        "Odjezd",
        "Departure",
        "출발",
        "Abfahrt",
        "Rhiitinnae",
        "Wyjazd"
      ],
      select_other: [
        "V rozpětí dnů se nachází jiná rezervace. Prosím, vyberte jiné datum.",
        "There is another reservation within days. Please select another date.",
        "일 이내에 다른 예약이 있습니다. 다른 날짜를 선택하세요.",
        "Innerhalb weniger Tage gibt es eine weitere Reservierung. Bitte wählen Sie ein anderes Datum.",
        "Soledei est ono raeserven in salēī potmon. Jon bersikii ono dateon.",
        "W ciągu dni jest kolejna rezerwacja. Wybierz inną datę.",
      ],
      select_twodays: [
        "Minimální počet nocí je 2",
        "The minimum number of nights is 2",
        "최소 숙박일수는 2박입니다.",
        "Die Mindestanzahl an Nächten beträgt 2",
        "Minimiyeonostī aritōn dea noterineurēī est 2",
        "Minimalna liczba noclegów to 2",
      ],
      name: [
        "Jméno a příjmení",
        "Name and Surname",
        "이름과 성",
        "Name und Nachname",
        "Nyaan i komnyaan",
        "Imię i nazwisko",
      ], email: [
        "Email",
        "Email",
        "이메일",
        "Email",
        "e-mēton",
        "Email",
      ],
      name_e: [
        "Jana Nováková",
        "John Brown",
        "최보라",
        "Johann Müller",
        "Eariana Dobaa-na",
        "Lech Kowałski",
      ], email_e: [
        "janicka.novakova@seznam.cz",
        "j.brown@yahoo.com",
        "choi_bora@naver.com",
        "j.muller@web.de",
        "eari.dobaa1@naraeji.co.mky",
        "l.kowalski@poczta.pl",
      ],
      codes: [
        "CZ",
        "GB",
        "KR",
        "DE",
        "CZ",
        "PL",
      ],
      pricing: [
        "Ceník",
        "Pricing",
        "가격표",
        "Preisliste",
        "Rhēimin",
        "Cennik"
      ], done_t: [
        "Závazně objednat",
        "Order",
        "구속력 있는 주문",
        "Eine verbindliche Bestellung",
        "Tagyaetti",
        "Wiążące zamówienie"
      ],
      continue_t: [
        "Pokračovat",
        "Continue",
        "계속",
        "Fortsetzen",
        "Dōjotti",
        "Kontynuować"
      ],
      translations: [
        {
          countrySelectorLabel: 'Předvolba',
          countrySelectorError: 'Vyberte zemi',
          phoneNumberLabel: 'Telefonní číslo',
          example: 'Příklad: '
        },
        {
          countrySelectorLabel: 'Area Code',
          countrySelectorError: 'Choose a country code',
          phoneNumberLabel: 'Phone Number',
          example: 'Example: '
        },
        {
          countrySelectorLabel: '국가 코드',
          countrySelectorError: '국가 선택',
          phoneNumberLabel: '전화 번호',
          example: '예시: '
        },
        {
          countrySelectorLabel: 'Landesvorwahl',
          countrySelectorError: 'Wähle ein Land',
          phoneNumberLabel: 'Telefonnummer',
          example: 'Beispiel: '
        },
        {
          countrySelectorLabel: 'Kxōdeun ggoseumalēī',
          countrySelectorError: 'Eoboleatti dea kxōdeun ggoseumalēī',
          phoneNumberLabel: 'Ggoseumalaritōn',
          example: 'Deikmeun: '
        },
        {
          countrySelectorLabel: 'Kod pocztowy',
          countrySelectorError: 'Wybierz kraj',
          phoneNumberLabel: 'Numer telefonu',
          example: 'Przykład: '
        },
      ],
      order: [
        "Objednávka",
        "Order",
        "주문",
        "Bestellen",
        "Tagyaen",
        "Zamów"
      ],
      info: [
        "Informace o vás",
        "Info about you",
        "당신에 대한 정보",
        "Informationen über dich",
        "Inpxol per du",
        "Informacje o Państwu"
      ],
      confirm: [
        "Potvrzení",
        "Confirmation",
        "확인",
        "Bestätigung",
        "Tvrdsson",
        "Potwierdzenie"
      ],
      additional: [
        "Další informace, které nám chcete sdělit:",
        "Additional informations you want to tell us:",
        "알려주고 싶은 추가 정보:",
        "Weitere Informationen, die Sie uns mitteilen möchten:",
        "Pelu inpxon du hwanidakii yekyaetti ana amAsx:",
        "Więcej informacji, które chce państwo nam przekazać:"
      ],
      your_order: [
        "Vaše objednávka",
        "Your Order Recapitulation",
        "주문 요약",
        "Zusammenfassung Ihrer Bestellung",
        "Dū tagyaen",
        "Podsumowanie zamówienia państwa"
      ],
      night_price: [
        "Cena za noc",
        "Price per night",
        "1박당 가격",
        "Preis pro Nacht",
        "Rheton por noterineumēī",
        "Cena za noc"
      ],
      twonights: [
        "Dvě noci (minimum)",
        "Two nights (minimum)",
        "2박(최소)",
        "Zwei Nächte (mindestens)",
        "Deobo noterineul (minimiyeon)",
        "Dwie noce (minimum)"
      ],
      night: [
        "noc",
        "night",
        "밤",
        "nächt",
        "nōterineun",
        "noc"
      ],
      threetosix: [
        "Tři až šest nocí",
        "Three to six nights",
        "3박 6일",
        "Drei bis sechs Nächte",
        "Teare tini sēk noterineul",
        "Trzy do sześciu nocy"
      ],
      sevenandmore: [
        "Sedm a více",
        "Seven or more",
        "7개 이상",
        "Sieben und mehr",
        "Sesīaa ahi pelu",
        "Siedem i więcej"
      ],
      locale: [
        "cs-CZ",
        "en-US",
        "ko-KR",
        "de-DE",
        "cs-CZ",
        "pl-PL"
      ],
      selected: {
        people: 0,
        total: "0 Kč",
        arrival: new Date(),
        depart: date,
        person: {
          name_surname: "",
          email: "",
          phone: "",
          phone_format: "",
          additional_info:"",
          phone_correct: false
        }
      },
      step: 0,
      price_builder: {
        base_price: [
        ],
        threetosixsale: [
        ],
        sevenandmoresale: [
        ],
        
      },
      people: [
        {
          name: [
            "Jeden až tři lidi",
            "One to three people",
            "1~3명",
            "Ein bis drei Personen",
            "Iino tini teare teutol",
            "Od jednej do trzech osób"
          ],
          price: 10,
          i: 0,
          icon: require("../assets/jednotlivec.svg")
        },
        {
          name: [
            "Čtyři až pět lidí",
            "Four to five people",
            "4~5명",
            "Vier bis fünf Personen",
            "Cīera tini pinpxe teutol",
            "Od czterech do piaćy osób"
          ],
          price: 5,
          i: 1,
          icon: require("../assets/dvojice.svg")
        },
        {
          name: [
            "Šest a více",
            "Six or more",
            "6개 이상",
            "Sechs und mehr",
            "Sēk ahi pelu",
            "Sześc i więcej"
          ],
          price: 0,
          i: 2,
          icon: require("../assets/rodina.svg")
        },
      ]
    }
  },
  computed: {
    lang_number() {
      return this.$parent.lang_number;
    },
    lang() {
      return this.$parent.lang;
    },
    is_disabled() {
      if(this.selected.person.name == ""||
        this.selected.person.phone == ""||
        this.selected.person.phone_correct == false
      )
      {
        return true;
      }
      return false;
    },
    today() {
      var now = new Date();
      var start = new Date(now.getFullYear(), 0, 0);
      var diff = now - start;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    },
    tts() {
      var sale_percentage = this.price_builder.threetosixsale[this.today] * 0.01;
      var today_price = this.price_builder.base_price[this.today];
      var price = today_price - (today_price * sale_percentage);
      return price;
    },
    sam() {
      var sale_percentage = this.price_builder.sevenandmoresale[this.today] * 0.01;
      var today_price = this.price_builder.base_price[this.today];
      var price = today_price - (today_price * sale_percentage);
      return price;
    },
    total() {
      var subtotal = 0;
      var startA = new Date(this.selected.arrival.getFullYear(), 0, 0);
      var startD = new Date(this.selected.depart.getFullYear(), 0, 0);
      var oneDay = 1000 * 60 * 60 * 24;
      
      const diffTime = Math.abs(this.selected.depart - this.selected.arrival);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      var arrivalDayOfYear = Math.floor((this.selected.arrival - startA) / oneDay);
      var departDayOfYear = Math.floor((this.selected.depart - startD) / oneDay);
      var departDayOfYearCounter = departDayOfYear;
      var yearDiff = this.selected.depart.getYear() - this.selected.arrival.getYear();
      departDayOfYearCounter = arrivalDayOfYear + diffDays;
      for (var i = arrivalDayOfYear; i <= departDayOfYearCounter; i++) {
        var d = i;
        if(d >= 365)
        {
          d = d - (365*yearDiff);
        }
        subtotal += this.price_builder.base_price[d+1];
        
      }
      subtotal = Math.round(subtotal - (subtotal * (this.people[this.selected.people].price) * 0.01));
      
      return subtotal;
    },
    nyaan() {
      if(this.lang_number == 4)
      {
        return [
          "Kxeiman",
          "Jerssean",
          "Caatinen",
          "Apxrīlean",
          "Māyeun",
          "Derkxean",
          "Dessilan",
          "Sxīsan",
          "Lēkesseon",
          "Kōssesseon",
          "Nyeonesseon",
          "Noyīsseon"
        ];
      }
      else
      {
        return undefined;
      }
    }
  }
  }
</script>